<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
			ref="list"
            :is_page="true"
        >
			<template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加单位</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.text=='1'">启用</span>
                <span v-else-if="data.text=='-1'">禁用</span>
            </template>
            <template slot="operation" slot-scope="data">
                <span v-auth="'change'">
                    <a @click="edit(data.record)">编辑</a>
                </span>
                <a-divider type="vertical"  v-auth="'change'"/>
                <span v-auth="'enable'">
                    <a @click="status(data.record)">{{data.record.status == 1 ? '禁用' : '启用'}}</a>
                </span>
                <a-divider type="vertical" v-auth="'enable'" />
                <span v-auth="'delete'">
                    <a-popconfirm title="您确定要删除吗?" @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
                </span>
            </template>
        </TableList>
		<EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">
		</EditPop>
    </div>
</template>

<script>
import TableList from '@/components/TableList'
import { getDrpGoodsUnit,delDrpGoodsUnit ,addDrpGoodsUnit,getDrpGoodsUnitCate,changeDrpGoodsUnitStatus } from '@/api/goods'
import EditPop from "@/components/EditPop";
const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "单位名称",
        dataIndex: "name",
    },
    {
        title: "单位缩写",
        dataIndex: "abbreviation",
    },
    {
        title: "分类",
        dataIndex: "cate_name",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    components: {
		TableList,
		EditPop
    },
    data() {
        return {
            get_table_list: getDrpGoodsUnit,
            columns,
            brand_data:[],
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键字",
                        placeholder: '请输入',
                        options: {

                        }
                    },
                    {
                        type: "select",
                        name: "cate_id",
                        title: "分类",
                        options: {},
                        list:[]
                    },
                ],
                ...this.$config.form_data_seo
			},
			form_data:{
				title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: "text",
                        name: "name",
                        title: "名称",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入名称"
                                }
                            ],
                        },
                    },
                    {
                        type: 'select',
                        name: 'cate_id',
                        title: "分类",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请选择分类",
                                    type:'number',
                                    trigger:'change'
                                }
                            ],
                        },
                        list:[]
                    },
                    {
                        type: 'text',
                        name: 'abbreviation',
                        title: '单位缩写',
                        options: {},
					},
                ]
			},
			visible:false,
        }
	},
    created(){
        this.getDrpGoodsUnitCate()
    },
	methods:{
        // 获取单位分类
        getDrpGoodsUnitCate(){
            getDrpGoodsUnitCate().then(res=>{
                let arr = res.data.list.map((item) => {
                    return {
                        key:item.value,
                        value : item.label
                    }
                });
                this.form_data.list[2].list = arr
                this.form_data_seo.list[1].list = arr
            })
        },
		add(){
			this.form_data.title = "添加"
			this.form_data.list.forEach(item => {
                item.options.initialValue = '';
			})
			this.visible = true;
		},
		edit(record){
			this.form_data.title = "编辑"
			let data = record
            this.form_data.list.forEach(item => {
                item.options.initialValue = data[item.name];
            })
            this.visible = true;
		},
		submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            addDrpGoodsUnit({
                data: {
                    ...data,
				},
				info:true
            }).then(res => {
				this.visible = false;
				this.$refs.list.get_list();
            })
        },
		del(record) {
            delDrpGoodsUnit({
                data: {
                    id: record.id
				},
				info:true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        status(data){
            changeDrpGoodsUnitStatus({
                data:{
                    id:data.id
                },info:true,loading:true
            }).then(()=>{
                this.$refs.list.get_list();
            })
        }
	}
}
</script>
<style type="text/css" scoped>
	.slot_test {
		display: flex;
	}
	.barnd_name{
		flex: 2;
	}
	.barnd_rt{
		flex: 1;
		margin-left: 10px;
	}
</style>